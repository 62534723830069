<template>
  <div class="add-form">
    <user-form :form="form" ref="form" create>
      <template #button>
        <el-form-item>
          <el-button type="primary" plain size="small" :loading="loading" @click="submit">
            {{ $t("handle.submit") }}
          </el-button>
        </el-form-item>
      </template>
    </user-form>
  </div>
</template>

<script>
import UserForm from "./Form.vue";
import { userAdd } from "api/user";

export default {
  components: {
    UserForm
  },

  data() {
    return {
      form: {
        name: "",
        password: "",
        verifyPassword: "",
        organizationUUID: "",

        email: "",
        bindings: {
          groupUserBindings: [],
          groupUserRoleBindings: [],
          organizationUserBindings: []
        }
      },

      loading: false
    };
  },

  methods: {
    submit() {
      this.$refs["form"].$refs["form"].validate(valid => {
        if (valid) {
          let groupUserBindings = this.$refs["form"].groupUserBindings;
          let groupUserRoleBindings = this.$refs["form"].groupUserRoleBindings;
          let effectBindings = this.$refs["form"].effectBindings;
          let organizationAdminFlag = this.$refs["form"].organizationAdminFlag;

          this.form.organizationUUID = this.organization;

          this.form.bindings = {
            groupUserBindings: [],
            groupUserRoleBindings: [],
            organizationUserBindings: []
          };

          for (let key in groupUserRoleBindings) {
            this.form.bindings.groupUserRoleBindings.push({
              providerUUID: key,
              roleUUID: groupUserRoleBindings[key],
              effect: effectBindings[key],
              organizationUUID: this.organization
            });
          }

          this.form.bindings.groupUserBindings = groupUserBindings.map(item => {
            return {
              groupUUID: item,
              organizationUUID: this.organization
            };
          });

          this.form.bindings.organizationUserBindings.push({
            organizationAdminFlag,
            organizationUUID: this.organization
          });

          this.loading = true;
          userAdd(this.form).then(response => {
            this.loading = false;
            if (response.code === 0) {
              this.$router.push("/admin/user");
              this.$store.dispatch("app/getUserInfo");
            }
          });
        }
      });
    }
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    }
  }
};
</script>

<style></style>
